<template>
  <Toast />
  <div class="intro-section">
    <div class="container-fluid">
      <div class="row gap-0">
        <div class="col-md-12 p-0">
          <Swiper
            :slides-per-view="1"
            slidesPerView="1"
            :dir="rtl"
            :navigation="{
              nextEl: '.swiper-button-intro-next',
              prevEl: '.swiper-button-intro-prev',
            }"
            :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
            :pagination="{ clickable: true }"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }"
            :parallax="true"
            spaceBetween="12"
            :breakpoints="{}"
          >
            <div class="swiper-button-intro-next"></div>

            <div class="swiper-button-intro-prev"></div>
            <!-- <swiper-slide >
              <div class="intro-card">
                <img
                  src="~/assets/img/introSlide2.webp"
                  alt=""
                  class="img-fluid fristItem"
                />
                <div class="container">
                  <div class="row left-item">
                    <div class="col-xl-7 col-lg-8 col-md-10">
                      <div class="content-box">
                        <img
                          src="~/assets/img/intro-img-content.svg"
                          alt=""
                          class="img-fluid position-relative h-100 web-image"
                        />
                        <img
                          src="~/assets/img/intro-img-content-mobile.svg"
                          alt=""
                          class="img-fluid position-relative h-100 mobile-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide> -->
            <swiper-slide>
              <div class="intro-card" style="background-image: url('/webp/intro_image.webp')">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-8 col-lg-8 col-md-12">
                      <div class="content-box h-100 py-4">
                        <h2 class="banner_title">
                          {{ $t('homePage.slider.slideOne.title') }}
                        </h2>
                        <p class="prag">
                          {{ $t('homePage.slider.slideOne.content') }}
                        </p>
                        <div class="button-box-banner-intro">
                          <Button :label="$t('Registernow')" @click="open_popup" />
                          <LocLink to="/why-acceptix" class="learn-more">
                            {{ $t('homePage.slider.slideOne.learnMore') }}
                          </LocLink>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 mobile-order d-lg-none">
                      <nuxt-img
                        src="/webp/intro_image.webp"
                        alt=""
                        class="img-fluid pt-4"
                        style="position: relative; object-fit: unset"
                        quality="100"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    position="right"
    v-model:visible="registerDialog"
    :header="$t('Register')"
    :style="{ width: '32rem' }"
    :modal="true"
    :draggable="false"
    class="journey-sidebar"
  >
    <div class="img-container d-flex flex-wrap justify-content-center">
      <h2 class="text-center pt-2 pb-2">{{ $t('alreadyAcceptixFamily') }}</h2>
      <p class="text-center mt-3 text-default">{{ $t('youAreLoggedIn') }}</p>
    </div>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {
      registerDialog: false,
    };
  },
  computed: {
    rtl() {
      return this.$i18n.locale == 'ar';
    },
  },
  methods: {
    async open_popup() {
      const token = useCookie('token');
      if (token.value) {
        this.$toast.add({
          summary: this.$t('alreadyAcceptixFamily'),
          severity: 'success',
          detail: this.$t('youAreLoggedIn'),
          life: 3000,
        });
      } else {
        this.$router.push(this.localePath('/auth/signup'));
      }
    },
  },
};
</script>

<style lang="scss">
.swiper {
  overflow-y: unset;
  .swiper-pagination {
    bottom: -35px;
  }
}
.button-box-banner-intro {
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  button {
    padding: 14px 30px;
    font-size: 18px;
    font-family: Oswald;
  }
  .learn-more {
    color: $color-primary;
    font-family: Oswald;
    font-weight: 500;
    text-decoration: underline !important;
    font-weight: 600;
    font-size: 20px;
    &:hover {
      text-decoration: none !important;
    }
  }
}
.swiper-button-intro-next,
.swiper-button-intro-prev {
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
}

.swiper-button-intro-prev {
  left: 0;
}
.swiper-button-intro-next {
  right: 0;
}
</style>
<style lang="scss" scoped>
.intro-section {
  padding-bottom: 28px;
}
.intro-card {
  min-height: 70vh;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 35%;
  @include dir(rtl) {
    background-position: left center;
  }
  @media (max-width: 1400px) {
    min-height: 45vh;
  }
  @media (max-width: 1200px) {
    min-height: 30vh;
  }
  @media (max-width: 991px) {
    background-image: none !important;
  }
  @include after() {
    // background-color: #000;
    opacity: 0.1;
    z-index: -1;
    inset: 0;
  }
  .content-box {
    height: 568px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    img {
      object-fit: contain;
      @include dir(rtl) {
        transform: rotateY(0deg);
      }
      &.mobile-image {
        height: 189px !important;
        display: none;
        @include media(772) {
          display: block;
        }
      }
      &.web-image {
        @include media(772) {
          display: none;
        }
      }
    }
    @include media(772) {
      height: 475px;
    }
    @include media(992) {
      align-items: center;
      text-align: center;
    }
    .name {
      @extend.min-title-font;
      font-size: 55px;
      font-weight: 700;
      color: $color-primary;
      @include media(992) {
        font-size: 40px;
      }
      @include media(772) {
        font-size: 28px;
      }
    }
  }
  .prag {
    font-family: Poppins;
    font-size: 20px;
    // font-weight: 700;
    color: #000000;
    @include media(772) {
      font-size: 16px;
    }
  }
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    &.fristItem {
      @include dir(rtl) {
        transform: rotateY(0deg);
      }
    }
    @include dir(rtl) {
      transform: rotateY(180deg);
    }
  }
}
.left-item {
  justify-content: left;
}

.banner_title {
  font-size: 60px;
  font-weight: 500;
  color: $color-primary;
  span {
    color: $color-secondary;
  }
  @include media(992) {
    font-size: 48px;
  }
}

.mobile-order {
  @include media(992) {
    order: -1;
  }
}
</style>
