<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-none d-lg-block">
          <nuxt-img
            :placeholder="[80, 80]"
            quality="100"
            loading="lazy"
            src="/assets/imgs/qenralImage.jpg"
            class="rounded w-100"
          />
        </div>
        <div class="col-lg-6 col-12">
          <div class="h-100 d-flex flex-column justify-content-center">
            <GeneralMinTitle
              class="text-start pb-4"
              :title="$t('homePage.wheretostudy.title')"
              :prag="$t('homePage.wheretostudy.choose')"
              :pragBold="$t('Acceptix') + '!'"
            />
            <HomeWhereToStudyForm />
          </div>
          <!-- <div class="form-box" ref="form_item">
            <GeneralInquiryForm />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img-box {
  img {
    object-fit: cover;
  }
  @include media(992) {
    display: none;
  }
}
.form-box {
  box-shadow: 0px 4px 10px 0px #00000026;
  padding: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
</style>
