<template>
  <Toast />
  <div class="JourneySection">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <GeneralMinTitle :title="$t('JourneySectionTitle')" />
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-4 col-lg-4 col-md-6 mb-4">
          <div class="JourneyBox">
            <div class="img-box">
              <img src="~/assets/img/student.jpg" alt="" class="img-fluid" />
            </div>
            <h4 class="name">{{ $t('Student') }}</h4>
            <p class="prag">
              {{ $t('JourneySectionBoxPrag1') }}
            </p>
            <div class="button-box">
              <Button style="width: 70%" @click="registButton" :label="$t('JoinNow')" />
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-lg-4 col-md-6 mb-4">
          <div class="JourneyBox">
            <div class="img-box">
              <img src="~/assets/img/partnershipForm.webp" alt="" class="img-fluid" />
            </div>
            <h4 class="name">{{ $t('ambassdor') }}</h4>
            <p class="prag">
              {{ $t('JourneySectionBoxPrag2') }}
            </p>
            <div class="button-box">
              <Button style="width: 70%" @click="openVisible" :label="$t('ConnectWithUs')" />
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-lg-4 col-md-6 mb-4">
          <div class="JourneyBox">
            <div class="img-box">
              <img src="~/assets/img/subA.webp" alt="" class="img-fluid" />
            </div>
            <h4 class="name">{{ $t('Institutepartnership') }}</h4>
            <p class="prag">
              {{ $t('JourneySectionBoxPrag3') }}
            </p>
            <div class="button-box">
              <Button style="width: 70%" @click="openBecomeAmbassadorDialog" :label="$t('PartnerWithUs')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    :header="$t('BecomeAnAmbassador')"
    :style="{ width: '32rem' }"
    position="right"
    :modal="true"
    :draggable="false"
    @hide="clearData"
    class="journey-sidebar"
  >
    <div class="img-box" v-if="!showDoneAmbassador">
      <div class="form-box pt-2">
        <form @submit.prevent="ambassadorForm">
          <div class="pb-3">
            <p class="pleasePrag">{{ $t('PleasePrag') }}</p>
          </div>
          <div class="pb-4">
            <div class="full-box">
              <InputText
                id="username"
                :class="[{ 'p-invalid': full_name_ambassador_error }]"
                v-model.trim="full_name_ambassador"
                class="w-100"
                required
                @input="fullNameText"
                :placeholder="$t('profile.fullName')"
              />
            </div>
            <InputComponentsInputMessage class="mt-2" v-if="full_name_ambassador_error">
              {{ full_name_ambassador_error_message }}
            </InputComponentsInputMessage>
          </div>

          <div class="pb-4">
            <div class="full-box">
              <InputText
                id="email"
                :class="[{ 'p-invalid': contact_email_error_ambassador }]"
                v-model.trim="email_ambassador"
                class="w-100"
                required
                :placeholder="$t('profile.email')"
                type="email"
                @input="validateEmail"
              />
            </div>
            <InputComponentsInputMessage v-if="contact_email_error_ambassador">
              {{ contact_email_error_message_ambassador }}
            </InputComponentsInputMessage>
          </div>
          <div class="pb-4">
            <UtilsCountryCodeDropDown
              v-model:selectedPhoneCode="selectedPhoneCode_ambassador"
              v-model:phoneNumber="phoneNumber_ambassador"
              required
              :phoneNumberError="phoneNumber_error"
              :phoneCodeError="phoneCodeError"
              :codeError="codeError"
              :messagePhoneCodeError="messagePhoneCodeError"
            />
          </div>
          <div class="pb-4">
            <div class="full-box">
              <InputText
                id="text"
                :class="[{ 'p-invalid': organization_ambassador_error }]"
                v-model.trim="organization_ambassador"
                class="w-100 mb-2"
                :placeholder="$t('Organization')"
                type="text"
                @input="organizationNameText"
              />
            </div>
            <InputComponentsInputMessage v-if="organization_ambassador_error">
              {{ organization_ambassador_error_message }}
            </InputComponentsInputMessage>
          </div>
          <div class="pb-4">
            <UtilsCountriesDropDown
              v-model:country="countryOfResidence_ambassador"
              :countryError="codeError"
              :errorMessage="messageCodeError"
              :placeholder="`${$t('Countryofresidence')}`"
              @change="addCountry"
              class="w-100 md:w-14rem"
            />
          </div>
          <div class="submit-container text-center">
            <Button type="submit" :label="$t('Sendrequest')" class="mt-4 button-send" />
          </div>
        </form>
      </div>
    </div>
    <DoneScreen v-else :fullWidth="true" :buttonBox="false" />
  </Dialog>

  <Dialog
    position="right"
    v-model:visible="becomeAmbassadorDialog"
    :header="$t('Institutepartnership')"
    :style="{ width: '32rem' }"
    :modal="true"
    :draggable="false"
    class="journey-sidebar"
  >
    <div class="form-box pt-2" v-if="!showDonePartnership">
      <form @submit.prevent="partnershipForm">
        <div class="pb-3">
          <p class="pleasePrag">{{ $t('PleasePrag') }}</p>
        </div>

        <!--country code-->
        <div class="pb-4">
          <UtilsCountriesDropDown
            v-model:country="CountryOfResidence"
            :countryError="codeError"
            :errorMessage="messageCodeError"
            :placeholder="`${$t('country')}`"
            @change="addCountry"
            class="w-100 md:w-14rem"
          />
        </div>

        <!-- School Name -->
        <div class="pb-4">
          <div class="full-box">
            <InputText
              id="schoolName"
              v-model.trim="school_name"
              @input="schoolNameText"
              class="w-100"
              required
              :placeholder="$t('schoolName')"
            />
          </div>
          <InputComponentsInputMessage class="mt-2" v-if="school_name_error">
            {{ school_name_error_message }}
          </InputComponentsInputMessage>
        </div>

        <!-- Contact First Name -->
        <div class="pb-4">
          <div class="full-box">
            <InputText
              id="contactFirstName"
              v-model.trim="contact_first_name"
              class="w-100"
              required
              :placeholder="$t('profile.first_name')"
              @input="firstNameText"
              :class="[{ 'p-invalid': contact_first_name_error }]"
            />
          </div>
          <InputComponentsInputMessage class="mt-2" v-if="contact_first_name_error">
            {{ contact_first_name_error_message }}
          </InputComponentsInputMessage>
        </div>

        <!-- Contact Last Name -->
        <div class="pb-4">
          <div class="full-box">
            <InputText
              id="contactLastName"
              v-model.trim="contact_last_name"
              class="w-100"
              required
              @input="lastNameText"
              :placeholder="$t('profile.last_name')"
            />
          </div>
          <InputComponentsInputMessage class="mt-2" v-if="contact_last_name_error">
            {{ contact_last_name_error_message }}
          </InputComponentsInputMessage>
        </div>

        <!-- Contact Email -->
        <div class="pb-4">
          <div class="full-box">
            <InputText
              id="contactEmail"
              v-model.trim="contact_email"
              class="w-100"
              required
              :placeholder="$t('profile.email')"
              @input="validateEmail"
              type="email"
            />
          </div>
          <InputComponentsInputMessage v-if="contact_email_error">
            {{ contact_email_error_message }}
          </InputComponentsInputMessage>
        </div>

        <!-- Phone Number -->
        <div class="pb-4">
          <UtilsCountryCodeDropDown
            v-model:selectedPhoneCode="selectedPhoneCode"
            v-model:phoneNumber="phoneNumber"
            required
            :phoneNumberError="phone_number_error"
            :phoneCodeError="phoneCodeError"
            :codeError="codeError"
            :messagePhoneCodeError="phone_number_error_message"
          />
        </div>

        <div class="text-center">
          <Button type="submit" :label="$t('Sendrequest')" class="mt-4 button-send" />
        </div>
      </form>
    </div>
    <DoneScreen v-else :fullWidth="true" :buttonBox="false" />
  </Dialog>

  <Dialog
    position="right"
    v-model:visible="registerDialog"
    :header="$t('Register')"
    :style="{ width: '32rem' }"
    :modal="true"
    :draggable="false"
    class="journey-sidebar"
  >
    <div class="img-container d-flex flex-wrap justify-content-center">
      <h2 class="text-center pt-2 pb-2">{{ $t('alreadyAcceptixFamily') }}</h2>
      <p class="text-center mt-3 text-default">{{ $t('youAreLoggedIn') }}</p>
    </div>
  </Dialog>
</template>

<script>
import { useAxios } from '@/composables/useAxios';

export default {
  async mounted() {
    const token = useCookie('token');
    if (token.value) {
      this.loggedin = true;
    }
  },
  data() {
    return {
      first_name: '',
      email: '',
      organization: '',
      selectedPhoneCode: null,
      phoneNumber: '',
      CountryOfResidence: null,
      visible: false,
      phoneCodeError: false,
      organization_error: false,
      OrganizationMessage: '',
      codeError: false,
      registerDialog: false,
      becomeAmbassadorDialog: false,
      loggedin: false,

      countryOfResidence_ambassador: null,
      organization_ambassador: '',
      phoneNumber_ambassador: '',
      selectedPhoneCode_ambassador: '',
      email_ambassador: '',
      full_name_ambassador: '',

      // School Name
      school_name: '',
      school_name_error: false,
      school_name_error_message: '',

      // Contact First Name
      contact_first_name: '',
      contact_first_name_error: false,
      contact_first_name_error_message: '',

      // Contact Last Name
      contact_last_name: '',
      contact_last_name_error: false,
      contact_last_name_error_message: '',

      // Contact Email
      contact_email: '',
      contact_email_error: false,
      contact_email_error_message: '',

      // Phone Number
      phone_number: '',
      phone_number_error: false,
      phone_number_error_message: '',

      // Contact Title
      contact_title: '',
      contact_title_error: false,
      contact_title_error_message: '',
      referredBySomeone: false,

      showDoneAmbassador: false,
      showDonePartnership: false,
    };
  },
  methods: {
    clearData() {
      this.full_name_ambassador_error = false;
      this.contact_email_error_ambassador = false;
      this.phoneNumber_error = false;
      this.phoneCodeError = false;
      this.organization_ambassador_error = false;
      this.codeError = false;
      this.full_name_ambassador = '';
      this.email_ambassador = '';
      this.organization_ambassador = '';
      this.phoneNumber_ambassador = '';
      this.selectedPhoneCode_ambassador = '';
      this.countryOfResidence_ambassador = null;
    },
    openBecomeAmbassadorDialog() {
      this.becomeAmbassadorDialog = true;
      this.organization_ambassador = '';
      this.countryOfResidence_ambassador = null;
      this.full_name_ambassador = '';
      this.email_ambassador = '';
      this.selectedPhoneCode_ambassador = null;
      this.phoneNumber_ambassador = '';
      this.showDoneAmbassador = false;
      this.showDonePartnership = false;
    },
    openVisible() {
      this.visible = true;
      this.CountryOfResidence = null;
      this.school_name = '';
      this.contact_first_name = '';
      this.contact_last_name = '';
      this.contact_email = '';
      this.selectedPhoneCode = null;
      this.phoneNumber = '';
      this.showDoneAmbassador = false;
      this.showDonePartnership = false;
    },
    registButton() {
      const token = useCookie('token');
      if (token.value) {
        this.$toast.add({
          summary: this.$t('alreadyAcceptixFamily'),
          severity: 'success',
          detail: this.$t('youAreLoggedIn'),
          life: 3000,
        });
      } else {
        this.$router.push(this.localePath('/auth/signup'));
      }
    },
    validateEmail() {
      this.contact_email_error = false;
      const emailRegex = /^(?![\d])[\w@\-#_.$!؟)(*,]+$/;
      this.isValidEmail = emailRegex.test(this.contact_email);
      this.isValidEmail_ambassador = emailRegex.test(this.email_ambassador);
      if (!this.isValidEmail && this.contact_email) {
        this.contact_email_error = true;
        this.contact_email_error_message = this.$t('EmailValidation');
      } else if (!this.isValidEmail_ambassador && this.email_ambassador) {
        this.contact_email_error_ambassador = true;
        this.contact_email_error_message_ambassador = this.$t('EmailValidation');
      } else {
        this.contact_email_error = false;
        this.contact_email_error_message = '';
      }
    },

    validateField(fieldName, errorData) {
      this[`${fieldName}_error`] = false;
      const NameRegex = /^(?![\d])[\w@\s\-#_.]{3,20}$/;
      if (!NameRegex.test(this[fieldName])) {
        this[`${fieldName}_error`] = true;
        this[`${fieldName}_error_message`] = this.$t(errorData.errorMessage);
      } else {
        this[`${fieldName}_error_message`] = '';
      }
    },

    firstNameText() {
      this.validateField('contact_first_name', {
        errorMessage: 'textGenericValidationError',
      });
    },

    fullNameText() {
      this.validateField('full_name_ambassador', {
        errorMessage: 'textGenericValidationError',
      });
    },
    organizationNameText() {
      this.validateField('organization_ambassador', {
        errorMessage: 'textGenericValidationError',
      });
    },

    schoolNameText() {
      this.validateField('school_name', {
        errorMessage: 'textGenericValidationError',
      });
    },

    lastNameText() {
      this.validateField('contact_last_name', {
        errorMessage: 'textGenericValidationError',
      });
    },

    ambassadorForm() {
      const formData = new FormData();

      if (this.organization_ambassador) {
        formData.append('organization_name', this.organization_ambassador);
      }
      formData.append('country_id', this.countryOfResidence_ambassador.id);
      formData.append('full_name', this.full_name_ambassador);
      formData.append('email', this.email_ambassador);
      formData.append('country_code', this.selectedPhoneCode_ambassador.code);
      formData.append('phone', this.phoneNumber_ambassador);

      const { api } = useAxios();
      api
        .post('/applications/become-ambassador', formData)
        .then(res => {
          this.showDoneAmbassador = true;
        })
        .catch(function (error) {
          if (error.response.data.errors.phone) {
            this.phone_number_error = true;
            this.phone_number_error_message = error.response.data.errors.phone[0];
            return false;
          }
        });
    },
    partnershipForm() {
      const { api } = useAxios();
      api
        .post('/applications/become-institute', {
          country_id: this.CountryOfResidence?.id,
          school_name: this.school_name,
          first_name: this.contact_first_name,
          last_name: this.contact_last_name,
          email: this.contact_email,
          country_code: this.selectedPhoneCode.code,
          phone: this.phoneNumber,
        })
        .then(res => {
          this.showDonePartnership = true;
        })
        .catch(function (error) {
          if (error.response.data.errors.phone) {
            this.phone_number_error = true;
            this.phone_number_error_message = error.response.data.errors.phone[0];
            return false;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.p-dialog-mask {
  z-index: 110199999999 !important;
}

.journey-sidebar {
  min-height: 100vh !important;
  max-height: 100vh !important;
  height: 100vh;
  margin: 0;
  .p-dialog-content {
    height: 100%;
    .img-box {
      height: 100%;
      .form-box {
        height: 100%;
        form {
          height: 100%;
          display: flex;
          flex-direction: column;
          .submit-container {
            margin-top: auto;
          }
        }
      }
    }
  }

  .img-box {
    img {
      border-radius: 10px;
      max-height: 390px;
      object-fit: cover;
    }
  }

  .img-container {
    min-height: 40vh;

    hr {
      margin-top: 0;
      margin-bottom: 25px;
    }

    img {
      border-radius: 10px;
      max-height: 390px;
      object-fit: cover;
    }
  }

  h2 {
    color: $color-primary;
    font-size: 32px;
  }

  .button-send {
    width: 250px !important;
  }

  @include media(772) {
    margin-top: 20px;

    min-height: calc(100vh - 20px) !important;
    max-height: calc(100vh - 20px) !important;

    @include dir(rtl) {
      margin-left: 20px;
    }

    @include dir(ltr) {
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.pleasePrag {
  color: $color-primary;

  font-size: 14px;
  font-weight: 400;
}

.button-send {
  width: 50%;

  @include media(772) {
    width: 100%;
  }
}

.JourneySection {
  margin-top: 60px;
}

.JourneyBox {
  padding: 40px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 10px 0px #00000026;
  text-align: center;
  border-radius: 10px;
  min-height: 670px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .img-box {
    img {
      width: 219px;
      height: 231px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .name {
    padding: 28px;

    @extend.min-title-font;
    font-size: 32px;
    font-weight: 700;
    color: $color-primary;
  }

  .prag {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #222222;
    padding-bottom: 40px;
  }

  .button-box {
  }
}
</style>
