<template>
  <div class="input-box">
    <div class="phone-item">
      <Dropdown
        v-model="selectedPhoneCode"
        :class="[{ 'p-invalid': selectedPhoneCodeError }]"
        :disabled="disabled"
        :emptyFilterMessage="$t('Noresultsfound')"
        :emptyMessage="$t('Noresultsfound')"
        :filterFields="['name', 'code']"
        :filterPlaceholder="$t('chooseacountry')"
        :loading="$store.state.countries.loading"
        :options="$store.state.countries.countriesCodeListRegistration"
        :placeholder="$t('code')"
        :required="required"
        :style="`width: ${width}`"
        :virtualScrollerOptions="{
          lazy: true,
          itemSize: 50,
        }"
        filter
        filterIcon="pi pi-search"
      >
        <template #option="slotProps">
          <div class="d-flex align-items-center gap-1">
            <div class="phone-country-flag" v-html="slotProps.option.show"></div>
            <div>(+{{ slotProps.option.code }})</div>
          </div>
        </template>
        <template #value="slotProps">
          <div v-if="selectedPhoneCode" class="d-flex align-items-center gap-1 mx-1">
            <div class="phone-country-flag" v-html="slotProps.value.show"></div>
            <div>(+{{ slotProps.value.code }})</div>
          </div>
          <span v-else class="mx-1">
            {{ slotProps.placeholder }}
          </span>
        </template>
      </Dropdown>
      <div class="w-100 full-box">
        <InputText
          id="phoneNumber"
          v-model="phoneNumber"
          :class="[{ 'p-invalid': phoneNumberError }]"
          :disabled="disabled"
          :placeholder="$t('profile.mobilenumber')"
          :required="required"
          class="w-100"
          type="number"
        />
      </div>
    </div>
    <InputComponentsInputMessage v-if="phoneCodeError" class="pt-2">
      {{ messagePhoneCodeError }}
    </InputComponentsInputMessage>
    <InputComponentsInputMessage v-if="codeError" class="pt-2">
      {{ messageCodeError }}
    </InputComponentsInputMessage>
  </div>
</template>

<script>
export default {
  emits: ['update:selectedPhoneCode', 'update:phoneNumber'],

  props: {
    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    width: {
      type: String,
      required: false,
      default: 'auto',
    },

    messagePhoneCodeError: {
      type: String,
      required: false,
      default: '',
    },

    messageCodeError: {
      type: String,
      required: false,
      default: '',
    },

    selectedPhoneCodeError: {
      type: Boolean,
      required: false,
      default: false,
    },

    phoneNumberError: {
      type: Boolean,
      required: false,
      default: false,
    },

    phoneCodeError: {
      type: Boolean,
      required: false,
      default: false,
    },

    codeError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedPhoneCode: null,
      phoneNumber: null,
    };
  },

  watch: {
    selectedPhoneCode(newValue) {
      this.$emit('update:selectedPhoneCode', newValue);
    },

    phoneNumber(newValue) {
      this.$emit('update:phoneNumber', newValue);
    },
  },

  methods: {
    setData(code, phone) {
      this.selectedPhoneCode = code;
      this.phoneNumber = phone;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.p-dropdown .p-dropdown-trigger) {
  .p-icon-spin {
    animation: loading 1s infinite linear;
  }
}

@keyframes loading {
  100% {
    transform: rotate(1turn);
  }
}
</style>
